<template>
  <div
    class="feedback-component feedback"
    :class="[
      this.$store.state.mode !== 'inperson' ? 'phone' : 'outside',
      this.$store.state.mobileView ? 'mobile' : 'desktop',
    ]"
  >
    <div
      class="feedback-text center-pop"
      :class="{ correct: this.$store.state.correct }"
    >
      <input
        class="audio"
        type="image"
        v-on:click="$store.dispatch('playVO', $store.state.feedback.id)"
        :src="require('../assets/play.png')"
        alt="play sound"
      />
      {{ $store.state.feedback.text }}
    </div>
    <button
      :id="'feedback-next-' + this.$store.state.mobileView"
      class="yellow-buttons"
      v-if="
        !$store.state.showResults ||
        (!$store.state.mobileView &&
          !$store.state.showResults &&
          $store.state.mode === 'inperson') ||
        (!$store.state.mobileView &&
          $store.state.showResults &&
          $store.state.mode === 'textmessage')
      "
      :class="$store.state.mobileView ? 'mobile' : 'desktop'"
      v-on:click="nextQuestion"
    >
      {{ this.$store.state.nextButtonText }}
    </button>
  </div>
</template>

<script>
export default {
  name: "Feedback",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    nextQuestion() {
      this.$store.commit("stopAllSounds");
      if (
        this.$store.state.showRoundFeedback &&
        this.$store.state.mode === "textmessage"
      ) {
        this.$store.state.showQuestion = false;
      } else {
        this.$store.state.showRewardModal = false;
        // this.$store.state.showQuestion = false;
        // this.$store.state.showFeedback = false;
        // this.$store.state.showResults = false;
        // this.$store.state.correct = false;
        // this.$store.state.setupCounter = 1;
        // this.$store.state.resultCounter = 1;
        // this.$store.state.results = {};

        if (
          !(
            this.$store.state.mode === "textmessage" &&
            this.$store.state.showFeedback &&
            this.$store.state.roundCounter <
              Object.keys(this.$store.state.content).length
          )
        ) {
          // }
          // else{
          this.$store.state.showFeedback = false;
          this.$store.state.setupCounter = 1;
          this.$store.state.resultCounter = 1;
          this.$store.state.results = {};
          this.$store.state.correct = false;
          this.$store.state.showResults = false;
          this.$store.state.showQuestion = false;
        }

        //  check for end of decision
        if (
          this.$store.state.decisionCounter ===
          Object.keys(
            this.$store.state.content["round" + this.$store.state.roundCounter]
          ).length -
            1
        ) {
          // check for end of rounds
          if (
            this.$store.state.roundCounter <
            Object.keys(this.$store.state.content).length
          ) {
            if (
              this.$store.state.mode === "textmessage" &&
              this.$store.state.showFeedback
            ) {
              this.$store.state.showQuestion = false;
              this.$store.state.showRoundFeedback = true;
            } else {
              this.nextRound();
            }
          } else {
            this.$store.commit("navigate", { route: "Outcome", data: "" });
          }
        } else {
          this.$store.state.showFeedback = false;
          this.$store.state.setupCounter = 1;
          this.$store.state.resultCounter = 1;
          this.$store.state.results = {};
          this.$store.state.correct = false;
          this.$store.state.showResults = false;
          this.$store.state.showQuestion = false;
          this.$store.state.decisionCounter += 1;
        }
        if (
          !this.$store.state.content["round" + this.$store.state.roundCounter][
            "decision" + this.$store.state.decisionCounter
          ].setup
        ) {
          this.$store.commit("changeQuestionState", true);
        }
        this.$store.commit("updateCurrent");
        // autoplay next audio
        if (this.$store.state.audio && !this.$store.state.showRoundFeedback) {
          this.$store.dispatch(
            "playVO",
            this.$store.state.roundCounter +
              "-" +
              (this.$store.state.showQuestion ? "question" : "setup") +
              "-" +
              this.$store.state.decisionCounter +
              (this.$store.state.showQuestion
                ? ""
                : "-" + this.$store.state.setupCounter)
          );
        }
        if((Object.keys(this.$store.state.content["round" + this.$store.state.roundCounter]).length - 1) === this.$store.state.decisionCounter){
          this.$store.commit(
          "setProgress",100);  
        }
        else{
            this.$store.commit(
          "setProgress",
          ((this.$store.state.decisionCounter - 1) /
            (Object.keys(
              this.$store.state.content[
                "round" + this.$store.state.roundCounter
              ]
            ).length -
              1)) *
            100
        );
        }
      }
    },
    nextRound() {
      this.$store.commit("stopAllSounds");
      this.$store.state.showRoundFeedback = false;
      this.$store.state.roundCounter += 1;
      this.$store.state.decisionCounter = 1;
      this.$store.state.setupCounter = 1;
      this.$store.state.resultCounter = 1;
      this.$store.state.roundScore = 0;
      this.$store.state.showFeedback = false;
      this.$store.state.results = {};
      this.$store.state.correct = false;
      this.$store.state.showResults = false;
      this.$store.state.showQuestion = false;
      window.dispatchEvent(new Event("resize"));
      if (
        this.$store.state.currentRoute === "Dialogue" &&
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.interaction === "in person"
      ) {
        this.$store.state.mode = "inperson";
      } else if (
        this.$store.state.currentRoute === "Dialogue" &&
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.interaction === "text message"
      ) {
        this.$store.state.mode = "textmessage";
      } else {
        this.$store.state.mode = "postit";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.feedback {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: clamp(12px, 2vw, 3vh);
  padding: 1vw;
  justify-content: space-evenly;
  align-items: center;
}

.feedback.mobile {
  font-size: clamp(12px, 3vw, 2vh);
}

.feedback.outside,
.feedback.choices.phone.mobile {
  margin: 10px 0;
}

.feedback-text {
  padding: 15px;
  border-radius: 20px;
  margin: 10px 0px;
  background-color: var(--light-red);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.feedback-text.correct {
  background-color: var(--light-green);
}
</style>
