<template>
  <div
    class="questions-component"
    :class="this.$store.state.mobileView ? 'mobile' : 'desktop'"
  >
    <div class="question-text">
      <input
        class="audio"
        type="image"
        v-on:click="$store.dispatch('playVO', decisionData.id)"
        :src="require('../assets/play.png')"
        alt="play sound"
      />
      {{ decisionData.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Questions",
  mounted() {},
  methods: {
    nextQuestion() {
      this.$store.commit("stopAllSounds");
      this.$store.state.decisionCounter ===
      Object.keys(
        this.$store.state.content["round" + this.$store.state.roundCounter]
      ).length
        ? this.$store.commit("navigate", { route: "Outcome", data: "" })
        : (this.$store.state.decisionCounter += 1);
    },
  },
  computed: {
    decisionData: function () {
      let key = Object.keys(
        this.$store.state.content["round" + this.$store.state.roundCounter][
          "decision" + this.$store.state.decisionCounter
        ].question
      )[0];
      return this.$store.state.content[
        "round" + this.$store.state.roundCounter
      ]["decision" + this.$store.state.decisionCounter].question[key];
    },
  },
  updated() {
    this.$store.commit("getFocusableItems");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.questions-component {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: clamp(12px, 2vw, 3vh);
  font-weight: bold;
}
.questions-component.mobile {
  font-size: clamp(12px, 3vw, 3vh);
}

.question-text {
  display: flex;
  align-items: center;
}
</style>
