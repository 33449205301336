var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"feedback-component feedback",class:[
    this.$store.state.mode !== 'inperson' ? 'phone' : 'outside',
    this.$store.state.mobileView ? 'mobile' : 'desktop' ]},[_c('div',{staticClass:"feedback-text center-pop",class:{ correct: this.$store.state.correct }},[_c('input',{staticClass:"audio",attrs:{"type":"image","src":require('../assets/play.png'),"alt":"play sound"},on:{"click":function($event){return _vm.$store.dispatch('playVO', _vm.$store.state.feedback.id)}}}),_vm._v(" "+_vm._s(_vm.$store.state.feedback.text)+" ")]),(
      !_vm.$store.state.showResults ||
      (!_vm.$store.state.mobileView &&
        !_vm.$store.state.showResults &&
        _vm.$store.state.mode === 'inperson') ||
      (!_vm.$store.state.mobileView &&
        _vm.$store.state.showResults &&
        _vm.$store.state.mode === 'textmessage')
    )?_c('button',{staticClass:"yellow-buttons",class:_vm.$store.state.mobileView ? 'mobile' : 'desktop',attrs:{"id":'feedback-next-' + this.$store.state.mobileView},on:{"click":_vm.nextQuestion}},[_vm._v(" "+_vm._s(this.$store.state.nextButtonText)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }