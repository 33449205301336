<template>
  <div
    class="hud-component"
    :class="[
      this.$store.state.mobileView ? 'mobile' : 'desktop',
      this.$store.state.mode === 'textmessage' ? 'phone' : 'outside',
    ]"
  >
    <div class="left-box" v-if="$store.state.currentRoute !== 'Outcome'">
      <div
        class="score-container"
        v-if="
          this.$store.state.mode === 'textmessage' &&
          !this.$store.state.mobileView
        "
      >
        <div v-if="$store.state.currentRoute !== 'Outcome'">
          Round {{ $store.state.roundCounter }}
        </div>
        <div v-else>Complete!</div>
        <div>Score: {{ $store.state.score }}</div>
      </div>
      <div class="progress-bar">
        <div
          class="progress"
          :style="{
            width: this.$store.state.percentageComplete + '%',
            backgroundImage:
              'url(' +
              require('@/assets/progressBar/fill/' +
                trackerImage +
                '-fill.png') +
              ')',
          }"
        >
          <img
            :src="
              require('@/assets/progressBar/indicator/' +
                trackerImage +
                '-indicator.png')
            "
          />
        </div>
        <div class="goal">
          <img
            class="goal-image"
            :src="
              require('@/assets/progressBar/goal/' + trackerImage + '-goal.png')
            "
          />
        </div>
      </div>
      <div class="instructions">
        <input
          class="audio"
          type="image"
          v-if="$store.state.roundCounter === 4"
          v-on:click="$store.dispatch('playVO', 'should-you-post')"
          :src="require('../assets/play.png')"
          alt="play sound"
        />
        {{ instructions }}
      </div>
    </div>

    <div
      class="right-box"
      v-if="
        this.$store.state.mode !== 'textmessage' || this.$store.state.mobileView
      "
    >
      <div class="score-container">
        <div v-if="$store.state.currentRoute !== 'Outcome'">
          Round {{ $store.state.roundCounter }}
        </div>
        <div v-else>Complete!</div>
        <div>Score: {{ $store.state.score }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hud",
  computed: {
    instructions: function () {
      let instructionText;
      if (this.$store.state.mode === "inperson") {
        instructionText = "Click on the conversation to continue";
      } else if (this.$store.state.mode === "textmessage") {
        instructionText = "Click on the phone to continue";
      } else {
        instructionText =
          this.$store.state.content["round" + this.$store.state.roundCounter]
            .info.instructions.text;
      }
      return instructionText;
    },
    trackerImage: function () {
      const image =
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.image;
      return image;
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.hud-component {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: 2fr 1fr;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
  font-size: clamp(12px, 2vw, 3vh);
}

.hud-component.mobile {
  font-size: clamp(12px, 3vw, 3vh);
}

.hud-component.desktop.phone {
  grid-template-columns: 1fr 1fr;
}

.left-box {
  display: flex;
  grid-row: 1;
  grid-column: 1;
  height: 100%;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  font-size: inherit;
  font-variation-settings: "wght" 700;
}

/* per client feedback will now be on right side for rounds 2 and 3*/
.desktop.phone > * .left-box {
  grid-column: 2;
}

.left-box > .score-container {
  border-radius: 0px 40px 40px 0px;
  align-self: flex-start;
  font-size: clamp(12px, 1.5vw, 3vh);
}

/* per client feedback will now be on right side for rounds 2 and 3*/
.desktop.phone > * .left-box > .score-container {
  align-self: flex-end;
  border-radius: 40px 0px 0px 40px;
  padding-right: calc(30px + 4vmin);
}

.progress-bar {
  border-style: solid;
  border-color: white;
  border-width: 5px;
  width: 80%;
  height: 20%;
  border-radius: 45px;
}

.progress {
  display: flex;
  height: 100%;
  border-radius: 45px;
  background-size: auto 100%;
  background-repeat: repeat-x;
  justify-content: flex-end;
  align-items: center;
}

.progress img {
  height: 150%;
  transform: translate(50%, 0px);
  z-index: 2;
}

.goal {
  display: flex;
  width: 100%;
  height: 200%;
  justify-content: flex-end;
  transform: translate(0%, -60%);
  z-index: 1;
}

.goal-image {
  transform: translate(50%, 0%);
  height: 100%;
}

.hud-component.mobile > .right-box {
  width: 75%;
}

.right-box {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  font-variation-settings: "wght" 700;
  font-size: inherit;
  width: 60%;
}

.phone > * .right-box {
  width: 80%;
}

.score-container {
  background-color: var(--score-color);
  color: white;
  border-radius: 40px 0px 0px 40px;
  padding: 10px 30px;
  text-align: left;
}

.instructions {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
