<template>
  <div
    class="answers-component"
    :class="this.$store.state.mobileView ? 'mobile' : 'desktop'"
  >
    <div
      class="choices"
      v-if="
        !$store.state.showFeedback ||
        ($store.state.showFeedback &&
          $store.state.currentQuestionType === 'resulting-response' &&
          ($store.state.mode === 'inperson' ||
            ($store.state.mode === 'textmessage' && $store.state.mobileView)))
      "
      :class="[
        this.$store.state.mode !== 'inperson' ? 'phone' : 'outside',
        this.$store.state.mobileView ? 'mobile' : 'desktop',
      ]"
    >
      <div
        v-for="choice in choiceData"
        :key="choice.id"
        class="choice-container"
      >
        <input
          class="audio"
          type="image"
          v-on:click="$store.dispatch('playVO', choice.id)"
          :src="require('../assets/play.png')"
          alt="play sound"
        />
        <button
          :id="choice.id"
          class="choice-buttons"
          v-on:click="showFeedback(choice.id)"
          :disabled="$store.state.showResults"
        >
          {{ choice.text }}
        </button>
      </div>
    </div>
    <Feedback v-else />
  </div>
</template>

<script>
import Feedback from "@/components/Feedback.vue";
export default {
  name: "Answers",
  components: {
    Feedback,
  },
  data() {
    return {
      newId: "",
      feedback: "",
    };
  },
  methods: {
    showFeedback(id) {
      this.$store.commit("updateCurrentItemById", id);
      this.$store.commit("getCurrentQuestionType");
      this.$store.commit("stopAllSounds");
      if (
        this.$store.state.content["round" + this.$store.state.roundCounter][
          "decision" + this.$store.state.decisionCounter
        ].result
      ) {
        this.$store.state.showResults = true;
        this.$store.state.results[id] = this.choiceData[id];
        this.newId = id.replace("choice", "feedback");
        this.$store.state.feedback = this.feedbackData[this.newId];

        if (this.$store.state.currentQuestionType === "resulting-response") {
          this.$store.state.showFeedback = false;
          if (this.$store.state.audio) {
            this.$store.dispatch("playVO", id);
          }
        } else {
          this.$store.state.showFeedback = true;
          // autoplay feedback
          if (this.$store.state.audio) {
            this.$store.dispatch("playVO", this.newId);
          }
        }

        for (const [key, value] of Object.entries(
          this.$store.state.content["round" + this.$store.state.roundCounter][
            "decision" + this.$store.state.decisionCounter
          ].result
        )) {
          if (key.split("-")[3] === id.split("-")[3]) {
            this.$store.state.results[key] = value;
          }
        }
      } else {
        this.newId = id.replace("choice", "feedback");
        this.$store.state.feedback = this.feedbackData[this.newId];

        this.$store.state.showFeedback = true;
        if (this.$store.state.audio) {
          this.$store.dispatch("playVO", this.newId);
        }
      }
      if (
        this.$store.state.decisionCounter ===
        Object.keys(
          this.$store.state.content["round" + this.$store.state.roundCounter]
        ).length -
          1
      ) {
        if (
          (!this.$store.state.currentQuestionType === "resulting-response" &&
            this.$store.state.mode === "inperson") ||
          (this.$store.state.currentQuestionType === "resulting-response" &&
            this.$store.state.mode === "textmessage" &&
            this.$store.state.mobileView === false &&
            this.$store.state.resultCounter ===
              Object.keys(this.$store.state.results).length + 1)
        ) {
          this.$store.state.showRoundFeedback = true;
        }
        this.$store.commit("setProgress", 100);
        this.$store.state.nextButtonText = "Next";
      }
      this.$store.state.selectedAnswer = id;
      if (this.choiceData[id].points) {
        if (this.choiceData[id].points > 0) {
          this.$store.state.correct = true;
        }
        this.$store.commit("updateScore", this.choiceData[id].points);
      }
    },
  },
  computed: {
    choiceData: function () {
      return this.$store.state.content[
        "round" + this.$store.state.roundCounter
      ]["decision" + this.$store.state.decisionCounter].choice;
    },
    feedbackData: function () {
      return this.$store.state.content[
        "round" + this.$store.state.roundCounter
      ]["decision" + this.$store.state.decisionCounter].feedback;
    },
  },
  updated: function () {
    this.$store.commit("getFocusableItems");
    if (this.$store.state.mode === "inperson") {
      this.$store.commit("scrollToEnd", "messages");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.answers-component {
  height: 100%;
  z-index: 1;
  font-size: clamp(12px, 2vw, 3vh);
  overflow: auto;
}

.answers-component.mobile {
  font-size: clamp(12px, 3vw, 3vh);
}

.choices {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: inherit;
  width: 100%;
}

.choices.outside,
.choices.phone.mobile {
  padding: 0px;
}

.choice-container {
  display: flex;
  align-items: center;
}

.choice-buttons {
  background-color: var(--yellow);
  font-size: clamp(12px, 2vw, 2.5vh);
  font-family: inherit;
  border-radius: 20px;
  padding: 20px;
  border: none;
  margin: 2vh 0;
  width: 80%;
  cursor: pointer;
}

.choice-buttons:disabled {
  opacity: 1;
  cursor: auto;
  color: inherit;
  background-color: lightgray;
  cursor: not-allowed;
}

.choices.outside > .choice-buttons,
.choices.choices.phone.mobile > .choice-buttons {
  padding: 10px;
  margin: 10px 0px;
}
</style>
