var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hud-component",class:[
    this.$store.state.mobileView ? 'mobile' : 'desktop',
    this.$store.state.mode === 'textmessage' ? 'phone' : 'outside' ]},[(_vm.$store.state.currentRoute !== 'Outcome')?_c('div',{staticClass:"left-box"},[(
        this.$store.state.mode === 'textmessage' &&
        !this.$store.state.mobileView
      )?_c('div',{staticClass:"score-container"},[(_vm.$store.state.currentRoute !== 'Outcome')?_c('div',[_vm._v(" Round "+_vm._s(_vm.$store.state.roundCounter)+" ")]):_c('div',[_vm._v("Complete!")]),_c('div',[_vm._v("Score: "+_vm._s(_vm.$store.state.score))])]):_vm._e(),_c('div',{staticClass:"progress-bar"},[_c('div',{staticClass:"progress",style:({
          width: this.$store.state.percentageComplete + '%',
          backgroundImage:
            'url(' +
            require('@/assets/progressBar/fill/' +
              _vm.trackerImage +
              '-fill.png') +
            ')',
        })},[_c('img',{attrs:{"src":require('@/assets/progressBar/indicator/' +
              _vm.trackerImage +
              '-indicator.png')}})]),_c('div',{staticClass:"goal"},[_c('img',{staticClass:"goal-image",attrs:{"src":require('@/assets/progressBar/goal/' + _vm.trackerImage + '-goal.png')}})])]),_c('div',{staticClass:"instructions"},[(_vm.$store.state.roundCounter === 4)?_c('input',{staticClass:"audio",attrs:{"type":"image","src":require('../assets/play.png'),"alt":"play sound"},on:{"click":function($event){return _vm.$store.dispatch('playVO', 'should-you-post')}}}):_vm._e(),_vm._v(" "+_vm._s(_vm.instructions)+" ")])]):_vm._e(),(
      this.$store.state.mode !== 'textmessage' || this.$store.state.mobileView
    )?_c('div',{staticClass:"right-box"},[_c('div',{staticClass:"score-container"},[(_vm.$store.state.currentRoute !== 'Outcome')?_c('div',[_vm._v(" Round "+_vm._s(_vm.$store.state.roundCounter)+" ")]):_c('div',[_vm._v("Complete!")]),_c('div',[_vm._v("Score: "+_vm._s(_vm.$store.state.score))])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }