var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"answers-component",class:this.$store.state.mobileView ? 'mobile' : 'desktop'},[(
      !_vm.$store.state.showFeedback ||
      (_vm.$store.state.showFeedback &&
        _vm.$store.state.currentQuestionType === 'resulting-response' &&
        (_vm.$store.state.mode === 'inperson' ||
          (_vm.$store.state.mode === 'textmessage' && _vm.$store.state.mobileView)))
    )?_c('div',{staticClass:"choices",class:[
      this.$store.state.mode !== 'inperson' ? 'phone' : 'outside',
      this.$store.state.mobileView ? 'mobile' : 'desktop' ]},_vm._l((_vm.choiceData),function(choice){return _c('div',{key:choice.id,staticClass:"choice-container"},[_c('input',{staticClass:"audio",attrs:{"type":"image","src":require('../assets/play.png'),"alt":"play sound"},on:{"click":function($event){return _vm.$store.dispatch('playVO', choice.id)}}}),_c('button',{staticClass:"choice-buttons",attrs:{"id":choice.id,"disabled":_vm.$store.state.showResults},on:{"click":function($event){return _vm.showFeedback(choice.id)}}},[_vm._v(" "+_vm._s(choice.text)+" ")])])}),0):_c('Feedback')],1)}
var staticRenderFns = []

export { render, staticRenderFns }